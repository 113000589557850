<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item 
                prop="financial_account_id" label="财务账户">
              <a-select placeholder="请选择账户" style="width: 100%" 
                        show-search
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSuggestFinancial"
                        v-model="searchForm.financial_account_id"
                        @change="handlerSearch">
                <a-select-option v-for="item in suggestAccountList"
                         :key="item.id"
                         :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
            
          <a-col :span="8">
            <a-form-model-item prop label="钱包编号">
              <a-input allowClear v-model="searchForm.card_no" placeholder="请输入钱包编号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="refunder_staff_id" label="操作人">
              <a-select
                show-search
                allowClear
                placeholder="请输入操作人"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="getStaffList"
                v-model="searchForm.refunder_staff_id"
              >
                <a-select-option
                  v-for="(item, index) in staffList"
                  :key="index"
                  :value="item.staff_id"
                >{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop label="申请日期">
              <a-range-picker v-model="searchForm.apply_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop label="退款日期">
              <a-range-picker v-model="searchForm.return_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="text-right mt-1">
            <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="refundTableRef"
      id="refundTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">钱包退余额</h4>
        </div>
        <div class="flex justify-between">
          <a-tabs type="card" class="w-full" v-model="searchForm.return_status" @change="handlerSearch">
            <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <template #card_no="{text}">
        <a-button class="p-0" type="link" @click="openUserDetail(text)">{{text}}</a-button>
      </template>
      <template #operation="{record}">
        <a-button class="p-0" type="link" @click="handlerDetail(record)">详情</a-button>
        <a-button class="p-0 ml-2" type="link" v-if="record.return_status !=2" @click="handlerRefund(record)">处理</a-button>
      </template>
    </base-table>

    <RefundAudit :id="activeId" v-if="isShowRefund" :show.sync="isShowRefund" />
    <RefundDetail :id="activeId" v-if="isShowDetail" :show.sync="isShowDetail" />
  </div>
</template>

<script>
import { getStaffList } from "@/api/customer.js"
import { getWalletRefundList } from '@/api/trading-manage/refund.js'
import moment from "moment"
import pageData from "./columns"
import RefundAudit from "./components/refund-audit"
import RefundDetail from "./components/refund-detail"
import { 
  getFinanceList
} from "@/utils/authData.js"

export default {
  components: { RefundAudit, RefundDetail },
  data() {
    return {
      staffList: [],
      // 是否显示详情
      isShowDetail:false,
      // 是否显示退款
      isShowRefund: false,
      ...pageData,
      // activeKey: 0,
      isChecked: false,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      total: 0,
      searchForm: {
        financial_account_id: undefined,
        // 状态（0-全部，1-未退款，2-已退款？）
        return_status: 0,
        card_no: '',
        apply_time_arr: [],
        apply_time_start: "",
        apply_time_end: "",
        return_time_arr: [],
        return_time_start: "",
        return_time_end: "",
        page: 1,
        page_count: 10,
      },
      accountList: [],
      suggestAccountList: [],

      isShowEdit: false,
      isShowEditCurrent: false,
      activeId: 0,
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "未退款" },
        { type_id: 2, type_name: "已退款" },
      ],
      tableData: [],
    }
  },
  async mounted() {
    this.accountList = await getFinanceList()
    this.suggestAccountList = this.accountList
    if(this.accountList.length == 1){
      this.searchForm.financial_account_id = this.accountList[0].id
      this.initData()
    }
  },
  methods: {
    moment,
    // handlerTypeChange(e) {
    //   this.activeKey = e
    //   this.searchForm.audit_status = e
    //   this.searchForm.page = 1
    //   this.initData()
    // },
    handlerSearch(){
      this.searchForm.page = 1
      this.initData()
    },
    async initData() {
      this.searchForm.apply_time_start = ""
      this.searchForm.apply_time_end   = ""
      if (this.searchForm.apply_time_arr.length > 0) {
        this.searchForm.apply_time_start = this.searchForm.apply_time_arr[0]
        this.searchForm.apply_time_end   = this.searchForm.apply_time_arr[1]
      }
      this.searchForm.return_time_start = ""
      this.searchForm.return_time_end   = ""
      if (this.searchForm.return_time_arr.length > 0) {
        this.searchForm.return_time_start = this.searchForm.return_time_arr[0]
        this.searchForm.return_time_end   = this.searchForm.return_time_arr[1]
      }
        const { data, code } = await getWalletRefundList(this.searchForm)
        if (code === 0) {
          this.tableData = data.list
          this.total = data.total_count
      }
    },
    // 操作人
    async getStaffList(value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },

    // 重置
    resetForm() {
      this.$refs.searchRef.resetFields()
      if(this.accountList.length == 1){
        this.searchForm.financial_account_id = this.accountList[0].id
      }else{
        this.searchForm.financial_account_id = undefined
      }
      this.initData()
    },
    // 详情
    handlerDetail(row) {
      this.activeId = row.id
      this.isShowDetail = true
    },
    // 退款
    handlerRefund(row) {
      this.activeId = row.id
      this.isShowRefund = true
    },

    handleSuggestFinancial(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestAccountList = this.accountList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestAccountList = this.accountList
      }
    },

    // 查看会员详情
    openUserDetail(card_no){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { card_no: card_no },
      });
      window.open(newPage.href, "_blank");
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>