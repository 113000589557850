<template>
  <div>
    <a-modal :visible.sync="show"
             width="700px"
             title="退款详情"
             :footer="null"
             @cancel="$emit('update:show', false)">
      <div class="footer" style="margin-top:0;">
        <a-form-model :model="form"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 退款金额 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="退款金额">
                <span class="font-bold">{{infoData.return_amount}}元</span>
                <!-- <span> （对应面额 {{infoData.apply_amount}}元）</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 客服 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="审核备注">
                <span>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 财务 备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="财务备注">
                <span>{{infoData.return_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- 财务 退款凭证 -->
          <a-row v-if="infoData.payment_voucher_image_url">
            <a-col :span="24">
              <a-form-model-item prop :labelCol="{ span: 4 }" label="退款凭证">
                <img class="img-class" :src="infoData.payment_voucher_image_url"
                     @click="handlerBigImg(infoData.payment_voucher_image_url)" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>

    <a-modal class="preview-class"
             title="退款图片"
             width="50%"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getWalletRefundDetail } from '@/api/trading-manage/refund.js'
// import { formatGoodsType } from '@/utils/type.js'


export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: '',

      img2List: [],
      infoData: {},
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      getWalletRefundDetail({ id: this.id }).then(res => {
        if (res.code === 0) {
          this.infoData = res.data
          this.img2List = res.data.payment_voucher_image_url
        }
      })
    },

    handlerBigImg (e) {
      this.previewImage = e
      this.previewVisible = true
    },

    handlerCancel () {
      this.isShow = false
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>