export const rowKey = "id";

export const columns = [
  // 门店列表
  {
    title: "卡号",
    dataIndex: "card_no",
    align: "center",
    width: "100",
    slots: { customRender: "card_no" },
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center",
  },
  {
    title: "审核时间",
    dataIndex: "audit_time",
    align: "center",
  },
  {
    title: "退款结果",
    dataIndex: "return_status",
    align: "center",
    width: "9%",
    slots: {
      customRender: 'return_status'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatStatus(val)
  },
  {
    title: "退款时间",
    dataIndex: "return_time",
    align: "center",
  },
  {
    title: "操作人",
    dataIndex: "returner",
    align: "center",
    width: "15%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: "130",
    slots: { customRender: "operation" },
  },
];

// 0-全部，1-未退款 2-已退款
export const statusType = [
  { id: 0, name: "全部" },
  { id: 1, name: "未退款" },
  { id: 2, name: "已退款" },
]

export function formatStatus(val) {
  return statusType.find(el=>el.id ===val).name
}
export default {
  rowKey,
  columns,
  statusType,
};
